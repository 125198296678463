import store from '@/store'
import { ref, watch } from '@vue/composition-api'
import moment from 'moment'

export default function useAllergyList() {
  const allergyList = ref([])
  const severityList = ref([])
  const statusList = ref([])
  const symptomList = ref([])
  const errorMessages = ref([])

  const loading = ref(false)
  const options = ref({
    sortBy: ['display'],
    sortDesc: [false],
    statusFilter: [true],
    itemsPerPage: 5
  })
  const selectedAllergy = ref([])
  const selectedSeverity = ref([])
  const selectedStatus = ref([])
  const selectedSymptom = ref([])
  const searchQuery = ref('')

  const patientAllergyColumns = [
    { text: 'ALLERGY', value: 'allergyDescriptionCode', align: 'center' },
    { text: 'SYMPTOMS', value: 'symptoms' },
    { text: 'IDENTIFIED', value: 'identificationDate' },
    { text: 'OBSERVED', value: 'lastOccurrence' },
    { text: 'ACTIVE', value: 'isActive' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const symptomTableColumns = [
    { text: 'Symptom', value: 'display' },
    { text: 'Status', value: 'isActive' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const tableColumns = [
    { text: 'Allergy', value: 'valueDescription' },
    { text: 'Type', value: 'codeType' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const readCode = code => {
    const type = new Array('None')

    if (code !== undefined) {
      switch (code) {
        case '1': {
          type[0] = 'Active Ingredient - Drug Allergy'
          break
        }
        case '2': {
          type[0] = 'Significant Inactive Ingredient - Drug Allergy'
          break
        }
        case '3': {
          type[0] = 'Food Allergy'
          break
        }
        case '4': {
          type[0] = 'Environmental Allergy'
          break
        }
        default: {
          type[0] = 'Undetermined'
        }
      }
    }

    return type
  }

  const formatDate = date => moment(date).format('YYYY-MM-DD') // moment(date).format('dddd, MMMM Do YYYY')

  // fetch allergies
  const fetchAllergies = () => {
    loading.value = true
    store
      .dispatch('app-allergy/fetchAllergies')
      .then(response => {
        allergyList.value = response

        allergyList.value.forEach(allergy => {
          allergy.codeType = readCode(allergy.codeCategory)
          allergy.typeDesc = `${allergy.valueDescription}|${readCode(allergy.codeCategory)}`
        })

        loading.value = false
      })
      .catch(error => {
        // TODO: Next Update - Show notification
        console.error('Oops, Unable to Fetch!')
        console.log('error :>> ', error.response)
        errorMessages.value = error.response.data.error
      })
  }

  watch([], () => {
    loading.value = true
    selectedAllergy.value = []
    fetchAllergies()
  })

  const fetchMedispanAllergies = () => {
    loading.value = true
    store
      .dispatch('app-allergy/postMedispanAllergies')
      .then(response => {
        console.log('Medispan Codes', response)
        allergyList.value = response

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  watch([], () => {
    loading.value = true
    fetchMedispanAllergies()
  })

  // fetch allergies
  const fetchPatientAllergies = patientId => {
    store
      .dispatch('app-allergy/fetchPatientAllergies', patientId)
      .then(response => {
        /*
        response.forEach(allergy => {
          allergy.identificationDate = formatDate(allergy.identificationDate)
          allergy.lastOccurrence = formatDate(allergy.lastOccurrence)
        })
        */

        console.log(response)

        allergyList.value = response

        loading.value = false
      })
      .catch(error => {
        console.log(error)

        // TODO: Next Update - Show notification
        console.error('Oops, Unable to Fetch!')
        console.log('error :>> ', error.response)
        errorMessages.value = error.response.data.error
      })
  }

  watch([], () => {
    loading.value = true
    selectedAllergy.value = []
    fetchPatientAllergies()
  })

  // post allergies
  const postPatientAllergy = patientAllergyDTO => {
    store
      .dispatch('app-allergy/postPatientAllergy', patientAllergyDTO)
      .then(response => {
        loading.value = false
      })
      .catch(error => {
        // TODO: Next Update - Show notification
        console.error('Oops, Unable to Post!')
        console.log('error :>> ', error.response)
        errorMessages.value = error.response.data.error
      })
  }

  watch([], () => {
    postPatientAllergy()
  })

  const deactivatePatientAllergy = patientAllergyId => {
    store
      .dispatch('app-allergy/deactivatePatientAllergy', patientAllergyId)
      .then(response => {
        loading.value = false
      })
      .catch(error => {
        // TODO: Next Update - Show notification
        console.error('Oops, Unable to Post!')

        errorMessages.value = error.response.data.error
      })
  }

  watch([], () => {
    deactivatePatientAllergy()
  })

  // Severities

  // fetch severities
  const fetchSeverities = () => {
    store
      .dispatch('app-allergy/fetchSeverities')
      .then(response => {
        severityList.value = response

        loading.value = false
      })
      .catch(error => {
        // TODO: Next Update - Show notification
        console.error('Oops, Unable to Fetch!')
        console.log('error :>> ', error.response)
        errorMessages.value = error.response.data.error
      })
  }

  watch([], () => {
    loading.value = true
    fetchSeverities()
  })

  // Statuses

  // fetch statuses
  const fetchStatuses = () => {
    store
      .dispatch('app-allergy/fetchStatuses')
      .then(response => {
        statusList.value = response

        loading.value = false
      })
      .catch(error => {
        // TODO: Next Update - Show notification
        console.error('Oops, Unable to Fetch!')
        console.log('error :>> ', error.response)
        errorMessages.value = error.response.data.error
      })
  }

  watch([], () => {
    loading.value = true
    fetchSeverities()
  })

  // Symptoms

  // fetch symptoms

  const fetchSymptoms = () => {
    store
      .dispatch('app-allergy/fetchSymptoms')
      .then(response => {
        symptomList.value = response

        loading.value = false
      })
      .catch(error => {
        // TODO: Next Update - Show notification
        console.error('Oops, Unable to Fetch!')
        console.log('error :>> ', error.response)
        errorMessages.value = error.response.data.error
      })
  }

  watch([], () => {
    loading.value = true
    fetchSymptoms()
  })

  const postSymptom = symptomName => {
    const symptom = [symptomName]

    store
      .dispatch('app-allergy/postSymptom', symptom)
      .then(response => {
        loading.value = false
      })
      .catch(error => {
        // TODO: Next Update - Show notification
        console.error('Oops, Unable to Post!')
        console.log('error :>> ', error.response)
        errorMessages.value = error.response.data.error
      })
  }

  watch([], () => {
    postSymptom()
  })

  const deleteSymptom = symptomId => {
    store
      .dispatch('app-allergy/deleteSymptom', symptomId)
      .then(response => {
        loading.value = false
      })
      .catch(error => {
        // TODO: Next Update - Show notification
        console.error('Oops, Unable to Post!')
        console.log('error :>> ', error.response)
        errorMessages.value = error.response.data.error
      })
  }

  watch([], () => {
    deleteSymptom()
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    loading,
    options,

    allergyList,
    selectedAllergy,
    fetchAllergies,
    fetchPatientAllergies,
    postPatientAllergy,
    deactivatePatientAllergy,

    severityList,
    fetchSeverities,
    selectedSeverity,

    statusList,
    fetchStatuses,
    selectedStatus,

    symptomList,
    fetchSymptoms,
    selectedSymptom,
    symptomTableColumns,
    postSymptom,
    deleteSymptom,

    tableColumns,
    patientAllergyColumns,

    searchQuery,
    readCode,

    formatDate,

    fetchMedispanAllergies,
  }
}
