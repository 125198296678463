import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"text-h5"},[_vm._v("Symptom Details")])]),_c(VCardText,[_c(VForm,{ref:"symptomForm"},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","lg":"6"}},[_c(VTextField,{attrs:{"label":"Symptom","hint":"Bloating","readonly":!_vm.isEdit,"rules":[_vm.required]},model:{value:(_vm.symptomDTO.display),callback:function ($$v) {_vm.$set(_vm.symptomDTO, "display", $$v)},expression:"symptomDTO.display"}})],1),_c(VCol,{attrs:{"cols":"12","lg":"6"}},[_c(VSwitch,{attrs:{"color":"primary","value":_vm.symptomDTO.isActive = true,"label":_vm.symptomDTO.isActive ? 'Active' : 'Inactive',"readonly":!_vm.isEdit},model:{value:(_vm.symptomDTO.isActive),callback:function ($$v) {_vm.$set(_vm.symptomDTO, "isActive", $$v)},expression:"symptomDTO.isActive"}})],1)],1),_c(VRow,[_c(VCol,{staticClass:"text-right"},[_c(VBtn,{attrs:{"color":"error darken-1","type":"reset","text":""},on:{"click":function($event){return _vm.closeForm()}}},[_vm._v(" Close ")]),(_vm.isEdit)?_c(VBtn,{attrs:{"color":"primary darken-1","text":""},on:{"click":function($event){return _vm.submitForm(_vm.symptomDTO)}}},[_vm._v(" Save ")]):_vm._e()],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }