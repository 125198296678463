import ApiConfig from './ApiConfig'
import {
  ConditionSeverityClient_V1,
  PatientAllergyClient_V1,
  ReactionStatusClient_V1,
  MedispanAllergyClient_V1,
} from './generated/api.generated.v1'

import variables from '@/variables'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAllergies() {
      const api = new PatientAllergyClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getAllergies()
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    postMedispanAllergies() {
      const api = new MedispanAllergyClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .medispanAllergyList()
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchPatientAllergies(context, patientId) {
      const api = new PatientAllergyClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getPatientAllergies(patientId)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    postPatientAllergy(context, patientAllergyDTO) {
      const api = new PatientAllergyClient_V1(new ApiConfig(), variables.INTERNAL_API)
      console.log('posting', patientAllergyDTO)

      return new Promise((resolve, reject) => {
        api
          .postPatientAllergy(patientAllergyDTO)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deactivatePatientAllergy(context, patientAllergyId) {
      const api = new PatientAllergyClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .deactivatePatientAllergy(patientAllergyId)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deletePatientAllergy(context, patientAllergyId) {
      const api = new PatientAllergyClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .deletePatientAllergy(patientAllergyId)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // Severity

    fetchSeverities() {
      const api = new ConditionSeverityClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getConditionSeverities()
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // Status
    fetchStatuses() {
      const api = new ReactionStatusClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getReactionStatuses()
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // Symptom
    fetchSymptoms() {
      const api = new PatientAllergyClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getAllergySymptoms()
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    postSymptom(context, symptom) {
      const api = new PatientAllergyClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .postAllergySymptom(symptom)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    deleteSymptom(context, symptomId) {
      const api = new PatientAllergyClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .deleteAllergySymptom(symptomId)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
