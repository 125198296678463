<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="text-h5">Symptom Details</span>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="symptomForm"
        >
          <v-row>
            <v-col
              cols="12"
              lg="6"
            >
              <v-text-field
                v-model="symptomDTO.display"
                label="Symptom"
                hint="Bloating"
                :readonly="!isEdit"
                :rules="[required]"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              lg="6"
            >
              <v-switch
                v-model="symptomDTO.isActive"
                color="primary"
                :value="symptomDTO.isActive = true"
                :label="symptomDTO.isActive ? 'Active' : 'Inactive'"
                :readonly="!isEdit"
              >
              </v-switch>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="text-right">
              <v-btn
                color="error darken-1"
                type="reset"
                text
                @click="closeForm()"
              >
                Close
              </v-btn>
              <v-btn
                v-if="isEdit"
                color="primary darken-1"
                text
                @click="submitForm(symptomDTO)"
              >
                Save
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import store from '@/store'
import { required } from '@core/utils/validation'
import { mdiClose } from '@mdi/js'
import { ref, onUnmounted } from '@vue/composition-api'
import allergyService from '@/services/AllergyService'
import useAllergyList from '@/store/useAllergyService'
import moment from 'moment'

export default {
  model: {
    prop: 'isSymptomFormActive',
    event: 'update:is-symptom-form-active',
  },
  props: {
    isSymptomFormActive: {
      type: Boolean,
      required: true,
    },
    isEdit: {
      type: Boolean,
      required: true,
    },
    symptomDTO: {
      type: Object,
      default: () => ({}),
    },
  },

  setup(props, { emit }) {
    //console.log(props.symptomDTO)

    const symptomForm = ref(null)
    const { postSymptom, formatDate } = useAllergyList()
    const menuID = ref(false)
    const menuLO = ref(false)
    const menuref = ref(null)

    const validate = symptomDTO => {
      if (symptomForm.value.validate(symptomDTO)) {
        return true
      }

      return false
    }

    const save = d => {
      menuref.value.save(d)
    }

    const refreshParent = () => {
      emit('refetch-data')
    }

    const closeForm = () => {
      emit('update-dialog')
    }

    const ALLERGY_APP = 'app-allergy'

    // Register module
    if (!store.hasModule(ALLERGY_APP)) {
      store.registerModule(ALLERGY_APP, allergyService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ALLERGY_APP)) store.unregisterModule(ALLERGY_APP)
    })

    return {
      symptomForm,
      validate,
      closeForm,
      refreshParent,

      postSymptom,
      formatDate,

      required,
      menuID,
      menuLO,
      menuref,
      save,

      icons: {
        mdiClose,
      },
    }
  },
  methods: {
    submitForm() {
      if (this.validate(this.symptomDTO)) {
        //console.log('posting', this.symptomDTO)
        this.postSymptom(this.symptomDTO.display)

        this.closeForm()
        this.refreshParent()
      } else {
        //console.log('Not Valid')
      }
    },
    reformatDate(date, type) {
      if (date !== '' && date !== null && date !== undefined) {
        this.date = moment(date).format('YYYY-MM-DD HH:mm Z')
        if (type === 'iD') {
          this.symptomDTO.identificationDate = moment(this.symptomDTO.identificationDate)
        }
        if (type === 'lO') {
          this.symptomDTO.lastOccurrence = moment(this.symptomDTO.lastOccurrence)
        }

        return this.date
      }

      return null
    },
  },
}
</script>
