<template>
  <div id="symptom-list">
    <!-- dialog -->

    <delete-confirmation
      v-model="isDeleteDialogActive"
      :selected-item="selectedItem"
      @delete-confirmation="submitDelete"
      @refetch-data="fetchData"
    ></delete-confirmation>

    <v-card>
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- search -->
        <v-text-field
          v-model="searchQuery"
          placeholder="Search"
          outlined
          hide-details
          dense
          class="allergy-search me-3 mb-4"
        >
        </v-text-field>

        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <v-btn
            v-model="isSymptomFormActive"
            color="primary"
            class="mb-4 me-3"
            @click="updateDialog(null), isEdit = true, isNew = true"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Add New Symptom</span>
          </v-btn>
        </div>
      </v-card-text>

      <!-- table -->
      <v-data-table
        v-model="selectedSymptom"
        :headers="symptomTableColumns"
        :items="symptomList"
        :options.sync="options"
        :search="searchQuery"
        :loading="loading"
        group-by="isActive"
        :group-desc="true"
      >
        <template #[`item.isActive`]="{item}">
          {{ readStatus(item.isActive) }}
        </template>
        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-item-group>
            <v-item link>
              <v-btn
                text
                x-small
                @click="isEdit = false, selectedItem = item, updateDialog('render')"
              >
                <v-icon
                  size="20"
                  class="me-2"
                >
                  {{ icons.mdiFileDocumentOutline }}
                </v-icon>
                <span>Details</span>
              </v-btn>
            </v-item>

            <v-item link>
              <v-btn
                text
                x-small
                @click="isEdit = true, selectedItem = item, updateDialog('edit')"
              >
                <v-icon
                  size="20"
                  class="me-2"
                >
                  {{ icons.mdiAccountEditOutline }}
                </v-icon>
                <span>Edit</span>
              </v-btn>
            </v-item>

            <v-item link>
              <v-btn
                text
                x-small
                @click="selectedItem = item, isDeleteDialogActive = !isDeleteDialogActive"
              >
                <v-icon
                  size="20"
                  class="me-2"
                >
                  {{ icons.mdiDeleteOutline }}
                </v-icon>
                <span>Delete</span>
              </v-btn>
            </v-item>
          </v-item-group>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      v-model="isSymptomFormActive"
      persistent
      max-width="75%"
    >
      <div>
        <v-card>
          <symptom-add-edit
            v-if="isSymptomFormActive"
            :is-symptom-form-active="isSymptomFormActive"
            :is-edit="isEdit"
            :symptom-d-t-o="selectedItem"
            @update-dialog="updateDialog"
            @refetch-data="fetchData"
          ></symptom-add-edit>
        </v-card>
      </div>
    </v-dialog>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import {
  mdiAccountOutline,
  mdiAlertCircleOutline,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiFileDocumentOutline,
  mdiOfficeBuilding,
  mdiPlus,
  mdiSquareEditOutline,
  mdiAccountEditOutline,
  mdiAccountLockOutline,
} from '@mdi/js'

import allergyService from '@/services/AllergyService'
import DeleteConfirmation from '@/components/validation/DeleteConfirmation.vue'
import useAllergyList from '@/store/useAllergyService'
import CommonList from '@/store/CommonService'
import SymptomAddEdit from './SymptomAddEdit.vue'

export default {
  components: { DeleteConfirmation, SymptomAddEdit },
  setup() {
    const ALLERGY_APP = 'app-allergy'

    // Register module
    if (!store.hasModule(ALLERGY_APP)) {
      store.registerModule(ALLERGY_APP, allergyService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ALLERGY_APP)) store.unregisterModule(ALLERGY_APP)
    })

    const {
      symptomList,
      symptomTableColumns,
      searchQuery,
      statusFilter,
      loading,
      options,
      selectedSymptom,
      readCode,

      fetchSymptoms,
      deleteSymptom,
    } = useAllergyList()

    const { readStatus } = CommonList()

    const isEdit = ref(false)
    const selectedItem = ref()
    const isDeleteDialogActive = ref(false)
    const isSymptomFormActive = ref(false)

    return {
      symptomList,
      symptomTableColumns,
      searchQuery,
      statusFilter,
      loading,
      options,
      selectedSymptom,
      isSymptomFormActive,
      selectedItem,
      isEdit,
      isDeleteDialogActive,
      readCode,
      readStatus,

      fetchSymptoms,
      deleteSymptom,
      icons: {
        mdiAccountOutline,
        mdiAlertCircleOutline,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiExportVariant,
        mdiFileDocumentOutline,
        mdiOfficeBuilding,
        mdiPlus,
        mdiSquareEditOutline,
        mdiAccountEditOutline,
        mdiAccountLockOutline,
      },
    }
  },
  mounted() {
    this.fetchSymptoms()
  },
  methods: {
    fetchData() {
      this.fetchSymptoms()
      this.$watch('symptomList', () => {
        //console.log(this.symptomList)
      })
    },
    submitDelete(symptom) {
      //console.log(symptom)
      this.deleteSymptom(symptom.id)
    },
    updateDialog(status) {
      //console.log('Status', status)
      //console.log('Dialog', this.isSymptomFormActive)
      if (status == null) {
        this.selectedItem = {}
      }
      this.isSymptomFormActive = !this.isSymptomFormActive
      //console.log('Selected Item', this.selectedItem)
    },
  },
}
</script>
